'use client';
import { useRouter } from 'next/navigation';
import React from 'react';

const NotFound = () => {
  const router = useRouter();

  return (
    <div className="flex flex-col items-center">
      <img className="mt-[140px]" src="/png/404.png" alt="Page Not Found" />
      <span className="mt-[40px] font-archivo text-white text-[40px] leading-[40px] font-semiBold">
        Page Not Found
      </span>
      <span className="mt-[16px] opacity-[70%] font-archivo text-white text-[16px] leading-[22.4px] font-regular">
        Looks like this page doesn&apos;t exist. Maybe the link is broken.
      </span>
      <button
        onClick={() => {
          router.replace('/');
        }}
        className="mt-[30px] flex flex-col items-center py-[20px] px-[24px] text-white text-[16px] font-spaceGrotesk font-semiBold leading-[16px]  border border-  bg-[#0E0E0E] rounded-[18px]"
      >
        <p>Take me home</p>
      </button>
    </div>
  );
};

export default NotFound;
